import { Box, FormControl, FormControlLabel, Radio } from "@mui/material";
import React from "react";
import Heading from "./reusable-components/Heading";
import Title from "./reusable-components/Title";
import Divider from "./reusable-components/Divider";
import Description from "./reusable-components/Description";
import SwitchButton from "./reusable-components/SwitchButton";
import language from "../assets/language.svg";
import MinDescription from "./reusable-components/MinDescription";

const Settings = () => {
  return (
    <Box sx={{ color: "white", m: {xs: '4rem 0 0 0.5rem', sm: '4rem 0 0 1rem', md:"4rem 0 0 1.5rem"} }}>
      <Box sx={{ mt: "2rem" }}>
        <Box sx={{ m: "3rem 0" }}>
          <Heading>Settings</Heading>
        </Box>

        <Box sx={{ m: "1rem 0" }}>
          <Title>Preferences</Title>
        </Box>

        <Divider />
        <Box sx={{ m: "1rem 0" }}>
          <Description>Theme</Description>
        </Box>
        <Box
          sx={{
            width: { xs:'95%', sm:"23rem"},
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            m: "2rem 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#292929",
              borderRadius: "5px",
              width: "13.5rem",
              justifyContent: "space-between",
              p: "0 5px",
              height: "2.5rem",
            }}
          >
            <Box>Turn on dark mode</Box>
            <SwitchButton />
          </Box>
          <Box>
            <FormControlLabel
              value="System"
              control={<Radio sx={{ color: "purple" }} />}
              label="System"
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "20rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            m: "2rem 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={language} alt="" />
            <Box sx={{ ml: "10px" }}>Language</Box>
          </Box>
          <Box>English</Box>
        </Box>
      </Box>

      <Box sx={{ mt: "2rem" }}>
        <Box sx={{ m: "7rem 0 1rem 0" }}>
          <Title>Privacy</Title>
        </Box>
        <Divider />
        <Box sx={{ m: "2rem 0" }}>
          <Box sx={{ m: "1rem 0 0 0" }}>
            <Title>Minimal Analytics</Title>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: {sm: '80%', md:"42rem"},
            }}
          >
            <MinDescription>
              Some basic anonymous data will help us understand how people use
              Zerion. Your wallet addresses, wallet providers, and transaction
              hashes are never sent to analytics
            </MinDescription>
            <Box>
              <SwitchButton />
            </Box>
          </Box>
        </Box>

        <Box sx={{ m: "2rem 0" }}>
          <Box sx={{ m: "1rem 0 0 0" }}>
            <Title>App Usage Analytics</Title>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: {sm: '80%', md:"42rem"},
            }}
          >
            <MinDescription>
              To make Zerion even better for you, we’ll use additional anonymous
              data, which might include wallet addresses, wallet providers, and
              transaction hashes. <br /> We will never associate any of this
              with you. Your personal data is never saved or shared with us.
            </MinDescription>
            <Box>
              <SwitchButton />
            </Box>
          </Box>
        </Box>

        <Box sx={{ m: "2rem 0" }}>
          <Box sx={{ m: "1rem 0 0 0" }}>
            <Title>Support Chat</Title>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: {sm: '80%', md:"42rem"},
            }}
          >
            <MinDescription>
              You can use the Intercom Chat to get help with any issues. Please
              activate it here to have an option of speaking with a friendly
              Zerion team member at any time
            </MinDescription>
            <Box>
              <SwitchButton />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: "2rem" }}>
        <Box sx={{ m: "7rem 0 1rem 0" }}>
          <Title>Advanced</Title>
        </Box>

        <Divider />

        <Box
          sx={{
            m: "3rem 0",
            borderRadius: '10px',
            bgcolor:"#201F1F",
            p: '1rem',
            width: 'fit-content'
          }}
        >
          <Description>Clear Cache</Description>
          <MinDescription>Probably wouldn’t help, but won’t hurt either</MinDescription>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
