import React from "react";
import Drawer from "./Drawer";
import Overview from "./Overview";
import { Box } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Send from "./Send";
import Settings from "./Settings";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

const Body = () => {
  return (
    <Box sx={{  m: "auto", overflowX: 'hidden' }}>      
        <BrowserRouter>
        <Drawer>
        <Routes>
        <Route path="/" element={<Overview />} /> 
          <Route path="/overview" element={<Overview />} />         
          <Route path="/send" element={<Send />} />         
          <Route path="/settings" element={<Settings />} />         
        </Routes>
        
        </Drawer>
        <ToastContainer theme="dark" progressStyle={{backgroundColor: '#A100ED'}}/>
        
        </BrowserRouter>
     
    </Box>
  );
};

export default Body;
