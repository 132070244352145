import { Button } from '@mui/material'
import React from 'react'

const PurpleButton = ({children, ...otherprops}) => {
  return (
    <Button
{...otherprops}
    sx={{
        borderRadius: '10px',
        width: '100%',
        bgcolor: '#A100ED',
        fontSize: {xs: '16px', md: '18px'},
        color: 'white',
        fontWeight: 'bold',
        p: {xs: '8px', md:'10px 1rem'},
        textTransform: 'none',
        "&:hover": {
            backgroundColor: '#7A00C4',
            color: 'white'
          }
    }}>
        {children}
    </Button>
  )
}

export default PurpleButton