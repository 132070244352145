import { Avatar, Box } from "@mui/material";
import React, { useContext, useState } from "react";
import Title from "./reusable-components/Title";
import navigation from "../assets/navig.svg";
import profile from "../assets/profile.svg";
import PurpleButton from "./reusable-components/PurpleButton";
import GrayInput from "./reusable-components/GrayInput";
import { WalletContext } from "./WalletContext";
import ModalBox from "./reusable-components/ModalBox";

const SendTokens = () => {

  const dummyTokens = [
    {
      Name : "USDT",
     address: "0x509Ee0d083DdF8AC028f2a56731412edD63223B9",
     }, 
     
     {
      Name : "DAI",
      address: "0x11fE4B6AE13d2a6055C8D9cF65c55bac32B5d844",
     }, 
     
     {
      Name : "WETH",
      address: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
     },
     {
      Name: "BTC",
      address: "0x2117069ca67b722fdbcc2c9895b3575060ec0587"
     }
  ]

  const [recipientAddress, setRecipientAddress] = useState("");
  const [senderAddress, setSenderAddress] = useState("")
  const [amount, setAmount] = useState(0)
  const {sendToken , walletAddress} = useContext(WalletContext);

  const dataRecievedHandler = (value) => {
    setRecipientAddress(value)
   
  }

  const submitHandler = (e) => {
    e.preventDefault();

    // console.log("SubmitValue:", recipientAddress, senderAddress, amount);
    sendToken(recipientAddress , senderAddress , amount)

    setRecipientAddress("");
    setSenderAddress("");
    setAmount("");
    
  }
 
  return (
    <Box>
      <Box
      sx={{p: { md: "0px 20px" },}}
      >
        <ModalBox token={dummyTokens} valueRecieved={dataRecievedHandler}/>
      
            {/* <GrayInput value={recipientAddress} onChange={(e) => {setRecipientAddress(e.target.value)}} placeholder="0xef6f28bca8c5b53fe969afc4271b95c6ebb8ca43" label="Token Address"/> */}
            <GrayInput value={senderAddress} onChange={(e) => {setSenderAddress(e.target.value)}} placeholder="0xef6f28bca8c5b53fe969afc4271b95c6ebb8ca43" label="Receiver's Address" />
            <GrayInput value={amount} onChange={(e) => {setAmount(e.target.value)}} placeholder="0" label="Amount" />
            <Box sx={{mt: '1rem'}}>
            <PurpleButton onClick={submitHandler}>Send Tokens</PurpleButton>
        </Box>
      </Box>


      {/* <Box
        sx={{
          bgcolor: "rgb(250, 250, 250, 0.04)",
          m: "10px 0",
          p: "15px 20px",
          border: '2px solid red'
        }}
      >
        <Box sx={{fontSize: {xs: '14px', md: '20px'}}}>Asset</Box>
        <Box
          sx={{
            height: "8rem",
            display: "flex",
            alignItems: "center",
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Box sx={{ fontSize: {xs: '18px', md:"24px"}, fontWeight: '600' }}>No available positions on Ethereum</Box>
            <Box sx={{ fontSize: {xs: '16px', md:"20px"}, fontWeight: '600', color: '#8C8C8C' }}>Balance:0</Box>
          </Box>
          <Box>
            <Box sx={{fontWeight: 'bold', fontSize: '64px', color: '#8C8C8C'}}>0</Box>
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
};

export default SendTokens;
