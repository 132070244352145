import './App.css';
import Body from './components/Body';
import WalletProvider from './components/WalletContext';

function App() {
  return (
 <WalletProvider >
    <Body />
    </WalletProvider>
  );
}

export default App;
