import { Box, Divider, Button, IconButton, Icon } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import shareIcon from "../assets/shareIcon.svg";
import profile from "../assets/profileimg.jpg";
import WhiteButton from "./reusable-components/WhiteButton";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Tokens from "./Tokens";
import NFTs from "./NFTs";
import History from "./History";
import { WalletContext } from "./WalletContext";
import truncateEthAddress from "truncate-eth-address";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import primaryBg from "../assets/primarybg.svg";
import PurpleButton from "./reusable-components/PurpleButton";
import GreyButton from "./reusable-components/GreyButton";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Overview = () => {
  const [value, setValue] = useState(0);
  const [visible, setVisible] = useState(true);

  const {
    walletAddress,
    connectWallet,
    loading,
    setWalletAddress,
    userBalance,
    setUserBalance,
  } = useContext(WalletContext);

  const [tokenList, setTokenList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    if (!walletAddress) {
      connectWallet();
    } else {
      setWalletAddress(null);
      setUserBalance(null);
    }
  };

  return (
    <Box
      sx={{ color: "white", mt: "4rem", height: "100vh", position: "relative" }}
    >
      <Divider />
      {userBalance ? (
        <>
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              alignItems: "center",
              justifyContent: "space-between",
              m: "2rem 1.5rem 0 0",
            }}
          >
            <Box
              sx={{
                height: "6rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ m: { xs: "0 0.5rem", md: "0 2rem" } }}>
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    minWidth: "4rem",
                    maxWidth: '7rem',
                    borderRadius: "10px",
                    width: "8vw"
                  }}
                />
              </Box>
              <Box>
                <Box
                  sx={{
                    fontSize: "17px",
                    fontFamily: "Lato",
                    fontWeight: "700",
                  }}
                >
                  {walletAddress && truncateEthAddress(walletAddress)}
                </Box>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Box
                    sx={{
                      fontSize: { xs: "32px", sm: "38px", md: "42px" },
                      fontWeight: "900",
                      fontFamily: "Lato",
                      opacity: "0.8",
                      minWidth: { xs: "10rem", sm: "12rem", md: "13.5rem" },
                      width: 'fit-content',
                    }}
                  >
                    {visible ? (
                      userBalance && `${parseFloat(userBalance).toFixed(3)} ETH`
                    ) : (
                      <Box>************</Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      bgcolor: "rgba(250, 250, 250, 0.18)",
                      borderRadius: "10px",
                      height: "fit-content",
                      cursor: "pointer",
                      "&:hover": { opacity: "0.5" },
                    }}
                    onClick={() => setVisible(!visible)}
                  >
                    <IconButton focusRipple>
                      {visible ? (
                        <VisibilityOffIcon sx={{ color: "white" }} />
                      ) : (
                        <RemoveRedEyeIcon sx={{ color: "white" }} />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                m: { xs: "2rem 0.5rem 2rem 0.5rem", md: "0" },
              }}
            >
              {/* <Box sx={{ m: { xs: '1rem 1rem 0 0', md: '5px 2rem 0 2rem' } }}>
            <img src={shareIcon} alt="Share" />
          </Box> */}
              <Box sx={{ width: { xs: "fit-content", md: "fit-content" } }}>
                {/* <WhiteButton>Remove Wallet</WhiteButton> */}
                {/* <Button
              sx={{
                borderRadius: "5rem",
                bgcolor: "white",
                color: "black",
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: "bold",
                p: "1rem 2rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#A100ED",
                  color: "white",
                },
              }}
              onClick={handleClick}
            >
              {loading
                ? "Initializing smart account..."
                : walletAddress
                ? `Remove Wallet`
                : "Connect Wallet"}
            </Button> */}
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%", mt: "3rem", minWidth: "10rem" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                mx: { xs: "0.5rem", md: "1.5rem" },
              }}
            >
              <Tabs
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#A100ED",
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: "#A100ED",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{
                    color: "white",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                  label="Tokens"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    color: "white",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                  label="NFTs"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{
                    color: "white",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                  label="History"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Tokens />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <NFTs />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <History />
            </CustomTabPanel>
          </Box>
        </>
      ) : (
        <>
         
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              m: {xs: '2rem 1rem 0 1rem', sm:"2rem 1.5rem 0 1.5rem"},
              // height: "70vh",
            }}
          >
            <Box
              sx={{
                width: {xs: '100%', sm:"35rem"},
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: '5rem'
              }}
            >
              <Box
                sx={{
                  fontSize: { xs: "32px", sm: "38px", md: "52px" },
                  fontWeight: "900",
                  fontFamily: "Lato",
                  textAlign: "center",
                }}
              >
                Connect your wallet
              </Box>
              <Box
                sx={{
                  textAlign: {xs: 'justify', sm:"center"},
                  fontSize: "17px",
                  fontFamily: "Lato",
                  fontWeight: "600",
                  wordSpacing: "2px",
                  opacity: "0.8",
                  p: '1rem 0 1.5rem 0'
                }}
              >
                Gain a thorough and comprehensive overview of your accounts and
                wallets, spanning across all networks. Link a WalletEx wallet or
                monitor any Ethereum address for a comprehensive view.
              </Box>
              <Box sx={{ width: "70%" }}>
                <PurpleButton onClick={handleClick}>
                  Connect Wallet
                </PurpleButton>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Overview;
