import { Box } from '@mui/material'
import React from 'react'

const Subtitle = (props) => {
  return (
    <Box sx={{
        fontSize: {xs: '18px', md:'24px', lg: '28px'},
        fontWeight: 'bold',
    }}>
        {props.children}
    </Box>
  )
}

export default Subtitle