import { Avatar, Box, CircularProgress, Icon, IconButton, Skeleton, SvgIcon } from "@mui/material";
import React, { useState , useEffect, useContext} from "react";
import Title from "./reusable-components/Title";
import navigation from "../assets/navig.svg";
import more from "../assets/more.svg";
import Subtitle from "./reusable-components/Subtitle";
import Description from "./reusable-components/Description";
import bgicon from "../assets/bgicon.svg";
import PurpleButton from "./reusable-components/PurpleButton";
import EmptyAlert from "./reusable-components/EmptyAlert";
import nav from "../assets/nav.svg";
import GreyButton from "./reusable-components/GreyButton";
import options from "../assets/options.svg";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import asseticon1 from "../assets/asseticon1.svg";
import asseticon2 from "../assets/asseticon2.svg";
import asseticon3 from "../assets/asseticon3.svg";
import { CovalentClient } from "@covalenthq/client-sdk";
import { ethers } from "ethers";
import { WalletContext } from "./WalletContext";
import { Ethereum, EthereumGoerli } from "@particle-network/chains";
import axios from "axios";
import { API_KEY } from "../utils/constants";
import truncateEthAddress from "truncate-eth-address";





const Tokens = () => {

  const [alignment, setAlignment] = useState("web");
  const [tokenList , setTokenList] = useState(null);
  const [txList , setTxList] = useState(null);
  const [isLoading , setIsLoading] = useState(null);

  const {walletAddress} = useContext(WalletContext);


  useEffect(() => {
    if(walletAddress)
    
    {
      setIsLoading(true);  
      fetchData();
    }
    
  } , [walletAddress])
 
  const fetchData = async () => {
    try {

      const client = new CovalentClient("cqt_rQjqjFgwBX8t9Mc3qhb4VBYrHgtj");
      const response = await client.BalanceService.getTokenBalancesForWalletAddress("eth-mainnet", walletAddress);
      // const txHistory = await client.TransactionService.getAllTransactionsForAddress("eth-goerli" , walletAddress);

      const txHistory = await axios.get(`https://api.etherscan.io/api?module=account&action=txlist&address=0xeb2eb5c68156250c368914761bb8f1208d56acd0&startblock=0&endblock=99999999&page=1&offset=10&sort=asc&apikey=${API_KEY}`)
      // console.log(txHistory?.data?.result); 

      setTxList(txHistory?.data?.result);




      if (!response.error) {
          setTokenList(response?.data?.items);
          setIsLoading(false)         
      } else {
        console.log(response.error_message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const boxStyle = {
    backgroundImage: { bgicon },
    backgroundSize: "cover", // You can customize this property based on your needs
  };

  return (
    <>
     
      <Box
        // sx={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "space-between",
        // }}
      >
        <Box sx={{ width: "fit-content" }}>
          <Title>Assets</Title>
        </Box>
      </Box>
      <Box
        sx={{
          // display: "flex",
          bgcolor: "#1F1D1D",
          p: { xs: "1rem", md: "3rem" },
          minHeight: "20rem",
          mt: "2rem",
          borderRadius: "10px",
        }}>
        <Box
          sx={{
            height: "100%",
            justifyContent: "center",
            m: "1rem 0",
            alignItems: "center",
            flexWrap: 'wrap',
            gap: 2
          }}
        >
          { isLoading ? 
          <Box sx={{ display: 'flex', justifyContent: 'center'}}>
              <Box sx={{ width: '100%' }}>
      <Skeleton animation="wave" height={100}/>
      <Skeleton animation="wave" height={100}  />
      <Skeleton animation="wave" height={100} />
    </Box>
            </Box> : tokenList?.map((value) => {
            return (
              <Box
                sx={{
                  bgcolor: "#121212",
                  minWidth: '8rem',
                  width: '40%',
                  m: "1rem 0",
                  position: "relative",
                  height: "11rem",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "10px",
                  border: "2px solid #2D2C2C",
                }}
              >
                <Avatar
                  src={value?.logo_url }
                  alt={value?.contract_ticker_symbol}
                  
                  sx={{
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                    height: {xs: '32px', md:"68px"},
                    width: {xs: '32px', md:"68px"},
                  }
                  
                }
                />
                <Box
                  sx={{
                    p: "1rem",
                  }}
                >
                  <Subtitle>{value?.contract_name} </Subtitle>
                  <Title>{`${parseFloat(ethers.utils.formatUnits(value?.balance?.toString() , value?.contract_decimals?.toString())).toFixed(2)} ${value?.contract_ticker_symbol}`} </Title>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default Tokens;
