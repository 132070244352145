import { Box, Input, InputLabel, TextField } from '@mui/material'
import React from 'react'

const GrayInput = ({children, ...otherprops}) => {
  return (
    <Box sx={{bgcolor: "rgb(250, 250, 250, 0.04)", p: {xs: '0.4rem', md:'1rem'}, width: '100%', m: '1rem 0', borderRadius: '10px'}}>
        <label>{otherprops.label}</label>
    <Input {...otherprops} sx={{
      color: 'white',
      width: '100%',
    //   minWidth: '20rem',
      fontSize: '18px',
    }}>
        {children}
    </Input>
    </Box>
  )
}

export default GrayInput