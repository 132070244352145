import { Box, CircularProgress } from '@mui/material'
import React , {useState , useContext , useEffect} from 'react'
import GreyButton from "./reusable-components/GreyButton"
import InputField from "./reusable-components/InputField"
import EmptyAlert from "./reusable-components/EmptyAlert"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import options from "../assets/options.svg";
import { WalletContext } from './WalletContext'
import axios from 'axios'
import { API_KEY } from '../utils/constants'
import truncateEthAddress from 'truncate-eth-address'
import { ethers } from 'ethers'

const depositHistory = [
    {
        date: '12/12/23',
        worth: '$12.99',
      status: "Deposited",
      asset: "ETH 0.22",
      key: "0xef6ca430xef6ca430xef6ca43",
    },
    {
        date: '12/12/23',
        worth: '$12.99',
      status: "Deposited",
      asset: "ETH 0.22",
      key: "0xef6ca430xef6ca430xef6ca43",
    },
    {
        date: '12/12/23',
        worth: '$12.99',
      status: "Deposited",
      asset: "ETH 0.22",
      key: "0xef6ca430xef6ca430xef6ca43",
    },
    {
        date: '12/12/23',
        worth: '$12.99',
      status: "Deposited",
      asset: "ETH 0.22",
      key: "0xef6ca430xef6ca430xef6ca43",
    },
    {
        date: '12/12/23',
        worth: '$12.99',
      status: "Deposited",
      asset: "ETH 0.22",
      key: "0xef6ca430xef6ca430xef6ca43",
    },
    {
        date: '12/12/23',
        worth: '$12.99',
      status: "Deposited",
      asset: "ETH 0.22",
      key: "0xef6ca430xef6ca430xef6ca43",
    },
  ];
  

const History = () => {



  const [txList , setTxList] = useState(null);
  const {walletAddress} = useContext(WalletContext);
  const [isloading , setIsLoading] = useState(false);

  const BASE_URL = "https://etherscan.io/tx/";

  useEffect(() => {
    if(walletAddress)
    
    {
      setIsLoading(true);
      fetchData();
    }
    
  }, [walletAddress])

  const fetchData = async () => {
    try {

 

      const txHistory = await axios.get(`https://api.etherscan.io/api?module=account&action=txlist&address=${walletAddress}&startblock=0&&page=1&offset=20&sort=asc&apikey=${API_KEY}`)
      // console.log(txHistory?.data?.result); 

      setTxList(txHistory?.data?.result);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
 

  return (<>
     {isloading ? <CircularProgress /> : <Box>
        {/* <Box sx={{
            display: 'flex',
            gap: 2,
        }}>
            <GreyButton>Transaction</GreyButton>
            <GreyButton disabled={true}>All assets</GreyButton>
        </Box>
        <Box sx={{
            mt: '3rem',
            display: {md:'flex'},
            alignItems: 'center',
            justifyContent: 'space-between'
        }}>
            <Box sx={{m: '1rem 0'}}>
            <InputField/>
            </Box>
            
            <GreyButton>Download CSV</GreyButton>
        </Box> */}
        <Box sx={{mt: '3rem'}}>
            {/* <EmptyAlert>No Transactions Yet</EmptyAlert> */}
            <TableContainer sx={{ overflowX: 'scroll', maxWidth: '100%', borderRadius: '10px' }}>
      <Table sx={{ minWidth: {lg:650}, bgcolor: '#1F1D1D', borderRadius: '10px', p:'10px' }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{display: {xs: 'none', lg: 'contents'}}}>
            <TableCell sx={{ color: '#A100ED', fontSize: '24px', border: 'none', fontWeight: 'bold'}}>From</TableCell>
            <TableCell sx={{ color: '#A100ED', fontSize: '24px', border: 'none', fontWeight: 'bold'}}  align="center">To</TableCell>
            <TableCell sx={{ color: '#A100ED', fontSize: '24px', border: 'none', fontWeight: 'bold'}}  align="center">Value</TableCell>
            {/* <TableCell sx={{ color: '#A100ED', fontSize: '24px', border: 'none', fontWeight: 'bold'}}  align="center">Action Taken</TableCell>
            <TableCell sx={{ color: '#A100ED', fontSize: '24px', border: 'none', fontWeight: 'bold'}}  align="left">Contact Address</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {txList?.map((tx) => (
            <TableRow
              key={tx?.hash}
              sx={{ bgcolor: '#282626', m: '1rem 0', borderBottom: '10px solid #1F1D1D' }}
            >
              <TableCell sx={{color: 'white', border: 'none', fontSize: '18px', display: {xs: 'none', lg: 'block'}}} component="th" scope="row">
                {tx?.hash && truncateEthAddress(tx?.hash)}
              </TableCell>
              <TableCell sx={{color: 'white', border: 'none' , fontSize: {xs: '14px', lg:'18px'}}} align="center">{truncateEthAddress(tx?.from)}</TableCell>
              <TableCell sx={{color: 'white', border: 'none' , fontSize: {xs: '14px', lg:'18px'},display: {xs: 'none', lg: 'block'}, height: '100%'}} align="center">{`${parseFloat(ethers.utils.formatEther(tx.value)).toFixed(2) } ETH`}</TableCell>
              <TableCell sx={{color: 'white', border: 'none', }} align='right'>
                <img src={options} style={{cursor: 'pointer'}} alt='...' onClick={()=>{  window.open(`${BASE_URL}${tx.hash}` , "_blank")}}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Box>
    </Box>}
    </>
  )
}

export default History
