import { Box, Divider } from "@mui/material";
import React, { useState } from "react";
import shareIcon from "../assets/shareIcon.svg"
import profile from "../assets/profile.svg";
import WhiteButton from "./reusable-components/WhiteButton";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Performance from "./Tokens";
import Tokens from "./Tokens";
import NFTs from "./NFTs";
import History from "./History";
import Title from "./reusable-components/Title";
import SendTokens from "./SendTokens";
import Heading from "./reusable-components/Heading";
import SendNfts from "./SendNfts";
import SendEthTransfer from "./SendEthTransfer";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const Send = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };


  return (
    <Box sx={{ color: "white", m: {xs: '4rem 0.5rem 1rem 0.5rem', sm: '4rem 1rem 1rem 1rem', md:"4rem 1.5rem 1rem 1.5rem"}, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
        <Heading>Send</Heading>
        <Divider />
      <Box sx={{ width: {xs: '95vw', sm:'100%'}, maxWidth: '40rem', mt: '1rem', bgcolor: '#1D1D1D', border: '2px solid #808080', borderRadius: '10px'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs variant="fullWidth" sx={{ 
           "& .MuiTabs-indicator": {
            backgroundColor: '#A100ED',
          },
          "& .MuiTab-root.Mui-selected": {
            color: '#A100ED', 
          },
          width: '100%',
          p: {xs: '1rem', md:'3rem'}
      }}
         value={value} onChange={handleChange} aria-label="Tabs">
          <Tab sx={{color: 'white', textTransform: 'none', fontWeight: 'bold', fontSize: {xs: '18px', md:'28px'}}} label="ETH Transfer" {...a11yProps(0)} />
          <Tab sx={{color: 'white', textTransform: 'none', fontWeight: 'bold', fontSize: {xs: '18px', md:'28px'}}} label="Tokens" {...a11yProps(1)} />
          <Tab sx={{color: 'white', textTransform: 'none', fontWeight: 'bold', fontSize: {xs: '18px', md:'28px'}}} label="NFTs" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <SendEthTransfer />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
       <SendTokens />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
       <SendNfts />
      </CustomTabPanel>
    </Box>
    </Box>
  );
};

export default Send;
