import { Box } from '@mui/material'
import React from 'react'

const MinDescription = (props) => {
  return (
    <Box sx={{
        fontSize: {xs: '14px', md:'18px'},
        color: 'rgb(230,230,230,0.5)',
        width: {xs: '75%',  lg:'36rem'},
    }}>
        {props.children}
    </Box>
  )
}

export default MinDescription