import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import GrayInput from "./GrayInput";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { Icon } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const style = {};

const ModalBox = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [gasValue, setGasValue] = React.useState("0");
  const [gasName, setGasName] = useState("0");

  const valueChageHandler = (e) => {
    setGasValue(e.target.value);
    props.valueRecieved(gasValue);
  }


  useEffect(() => {
    
    props.valueRecieved(gasValue);

  }, [gasValue])



  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: "5px 10px",
        bgcolor: "rgb(250, 250, 250, 0.04)",
        p: { xs: "0.4rem", md: "1rem" },
        width: "100%",
        m: "1rem 0",
        borderRadius: '10px'
      }}
    >
      <Box sx={{ m: "0 1rem", overflow: "hidden", width: "10rem" }}>
        <Box
          sx={{
            fontSize: "18px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {gasValue}
        </Box>
      </Box>
      <Button
        endIcon={<ArrowDropDownIcon />}
        onClick={handleOpen}
        sx={{
          border: "2px solid white",
          color: "white",
          bgcolor: "#151515",
          borderRadius: "10px",
          fontSize: {xs: '12px', md:'15px'}
        }}
      >
        Select Token
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90vw", sm: "70vw", md: 600 },
              bgcolor: "#000000",
              border: "2px solid #000000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: "4rem" }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                <GrayInput
                value={gasValue}
                  label="Custom token"
                  placeholder="Type or paste your token here."
                  onChange={valueChageHandler}
                />
              </form>
            </Typography>
            {props.token.map((value) => {
              return (
                <Box
                  onClick={() => {
                    setGasValue(value.address);
                    setGasName(value.Name);
                    handleClose();
                    props.valueRecieved(value.address);
                  }}
                  key={value.address}
                  sx={{
                    bgcolor: "rgb(250, 250, 250, 0.04)",
                    cursor: "pointer",
                    p: { xs: "0.4rem", md: "1rem" },
                    width: "100%",
                    m: "1rem 0",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    transitionDuration: "0.3",
                    "&:hover": {
                      color: "#7A00C4",
                      bgcolor: "#090909",
                    },
                  }}
                >
                  {/* <LocalGasStationIcon /> */}
                  <Box sx={{ m: "0 1rem", overflow: "hidden" }}>
                    <Box>{value.Name}</Box>
                    <Box
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: "12px",
                      }}
                    >
                      {/* {value.Address} */}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default ModalBox;
