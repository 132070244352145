import { ParticleNetwork, WalletEntryPosition } from '@particle-network/auth'
import { ParticleProvider } from '@particle-network/provider'
import { EthereumGoerli } from '@particle-network/chains'
import {  createContext, useState } from 'react'
import { ethers } from 'ethers'
import { toast } from 'react-toastify'
import ChainEx from '@chainex/chainex-sdk'
import {  RPC_URL } from '../utils/constants'
import ERC20ABI from "./../utils/ERC20ABI.json"
import ERC721ABI from "./../utils/ERC721ABI.json"

export const WalletContext = createContext()

const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(null)
  const [loading, setLoading] = useState(false)
  const [client, setClient] = useState(null)
  const [provider, setProvider] = useState(null)
  const [userBalance , setUserBalance] = useState(null);



  const isValidEthereumAddress = (address) => {
    // Ethereum address regular expression
    const ethereumAddressPattern = /^0x[a-fA-F0-9]{40}$/;

    // Check if the address matches the pattern
    return ethereumAddressPattern.test (address);
  }

  const intializingSDK = async () => {
    setLoading(true)
    try {
      const chain =
        '0xGS-12c04e82bec385ab1019d8ad3e506ffdbdf4627ce34843a090fbc3d02da85506'

      const particle = new ParticleNetwork({
        projectId: 'c646c59d-ad74-44e9-8277-93acf5a82d98',
        clientKey: 'cSHaWxVxKviTwL2qO3ipAVVmOIFfduyU1XIY0CIz',
        appId: '946d4c26-bf3f-4889-9a0b-fd9dfb5eed2a',
        chainName: EthereumGoerli.name, //optional: current chain name, default Ethereum.
        chainId: EthereumGoerli.id, //optional: current chain id, default 1.
        wallet: {
          //optional: by default, the wallet entry is displayed in the bottom right corner of the webpage.
          displayWalletEntry: true, //show wallet entry when connect particle.
          defaultWalletEntryPosition: WalletEntryPosition.BR, //wallet entry position
          uiMode: 'dark', //optional: light or dark, if not set, the default is the same as web auth.
          supportChains: [{ id: EthereumGoerli.id, name: EthereumGoerli.name }], // optional: web wallet support chains.
          customStyle: {} //optional: custom wallet style
        },
        securityAccount: {
          //optional: particle security account config
          //prompt set payment password. 0: None, 1: Once(default), 2: Always
          promptSettingWhenSign: 1,
          //prompt set master password. 0: None(default), 1: Once, 2: Always
          promptMasterPasswordSettingWhenLogin: 1
        }
      })
      const particleProvider = new ParticleProvider(particle.auth)
      const ethersProvider = new ethers.providers.Web3Provider(particleProvider)
      console.log('Ethers Provider', ethersProvider)

      setProvider(ethersProvider)

      const chainEx = new ChainEx(chain)
      setClient(chainEx)
      const options = {
        chainId: 5, // Required: Replace with the desired chain ID
        rpcUrl: RPC_URL, // Optional: Specify your custom RPC URL if needed
        isSponsored: true
      }
      const smartAccount = await chainEx.initalize(ethersProvider, options)
      console.log('Smart Account', smartAccount)

      setWalletAddress(smartAccount.smartAccountAddress)

      const ethBal = await ethersProvider.getBalance(smartAccount.smartAccountAddress.toString());
      setUserBalance(ethers.utils.formatEther(ethBal.toBigInt()));


      toast.success('Wallet Connected successfully', {
        position: toast.POSITION.BOTTOM_LEFT
      })
      setLoading(false)
    } catch (err) {
      toast.error('Error while connecting wallet', {
        position: toast.POSITION.BOTTOM_LEFT
      })
      setLoading(false)
      console.log('Error', err)
    }
  }

  const connectWallet = async () => {
    if (!walletAddress) {
      intializingSDK()
    }
  }

  const sendToken = async (tokenAddress , receiverAddress, amount)=> {
       
    try{
      if(isValidEthereumAddress(tokenAddress) && isValidEthereumAddress(receiverAddress)){

        const _underlyingToken = new ethers.Contract (
          tokenAddress,
          ERC20ABI,
          provider
        );
        const decimals = await _underlyingToken.decimals ();

        const bal = await _underlyingToken.balanceOf(walletAddress)
        const balanceOfAccount = bal.toBigInt();

        const amountToTransfer = ethers.utils
        .parseUnits(amount, decimals)
        .toBigInt()

        if(parseInt(amountToTransfer) <= 0 ){
            alert("Cannot transfer 0 tokens");
            return;

        }
        if (balanceOfAccount < amountToTransfer) {

        alert("Insufficient Balance");
        return;
      }


      const transaction = {
        target: tokenAddress,
        data: _underlyingToken.interface.encodeFunctionData('transfer', [
          receiverAddress,
          amountToTransfer
        ])
      }

      const userOpHash = await client.sendUserOp(transaction)
      console.log(userOpHash);
      alert("Token transfered successfully...")

      }else{
        console.log("Invalid receiver or token address");
      
      }


    }catch(error){
        console.log(error);
    }
  }

  const sendETH = async(receiverAddress , amount) =>{
    try{
  
         
        const amountOfEth = (ethers.utils.parseEther(amount)).toString();
        if(isValidEthereumAddress(receiverAddress)  , parseInt(amountOfEth) > 0){
        //   console.log(receiverAddress , amountOfEth);

        const tx = {
            target: receiverAddress,
            data: "0x",
            value: amountOfEth
           
          }


          const userOpHash = await client.sendUserOp(tx)
          console.log(userOpHash);



        
        }else{
            alert("Either amount or address is not correct")
        }

    }catch(error){
        console.log(error);
    }
  }

  const transferNFT = async (contractAddress , receiverAddress , tokenID)=>{

    try{

    if(isValidEthereumAddress(contractAddress) && isValidEthereumAddress(receiverAddress) && (parseInt(tokenID) > 0) ){


      const nftContract = new ethers.Contract (
        contractAddress,
        ERC721ABI,
        provider
      );


      const transaction = {
        target: contractAddress,
        data: nftContract.interface.encodeFunctionData('transferFrom', [
          walletAddress,
          receiverAddress,
          tokenID
        ])
      }

      const userOpHash = await client.sendUserOp(transaction)
      console.log(userOpHash);
      alert("NFT transfered successfully...")
      
    }else{
      toast.error("Something wrong..." , {
        autoClose:1000
      })
    }

    }catch(error){
      console.log(error);
    }

      
  }


 

  return (
    <WalletContext.Provider
      value={{
        walletAddress,
        connectWallet,
        loading,
        provider,
        client,
        setWalletAddress,
        sendETH,
        sendToken,
        transferNFT,
        userBalance,
        setUserBalance
}}
    >
      {children}
    </WalletContext.Provider>
  )
}

export default WalletProvider