import { Box } from '@mui/material'
import React from 'react'

const Description = (props) => {
  return (
    <Box sx={{
        fontSize: {xs:'12px', md:'16px'},
        fontWeight: '600',
    }}>
        {props.children}
    </Box>
  )
}

export default Description