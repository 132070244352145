import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import profile from "../assets/profileimg.jpg";
import gas from "../assets/gas.svg";
import ham from "../assets/ham.svg";
import search from "../assets/search.svg";
import { Link } from "react-router-dom";
import { WalletContext } from "./WalletContext";
import truncateEthAddress from "truncate-eth-address";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { API_KEY } from "../utils/constants";
import Send from "@mui/icons-material/Send";
import { RampLeft } from "@mui/icons-material";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import InterestsIcon from "@mui/icons-material/Interests";
import logo from "../assets/logo.svg";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { Badge, Button, CircularProgress, Icon } from "@mui/material";
import InputField from "./reusable-components/InputField";
import { useContext } from "react";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const drawerWidth = 210;

function ResponsiveDrawer(props) {
  const notify = () => toast("Coming soon!");

  const {
    walletAddress,
    connectWallet,
    loading,
    setWalletAddress,
    userBalance,
    setUserBalance,
  } = useContext(WalletContext);

  const [url, setUrl] = React.useState();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [gasPrice, setGasPrice] = React.useState(null);
  const [navUrl, setNavUrl] = useState("Overview");

  const handleClick = () => {
    if (!walletAddress) {
      connectWallet();
    } else {
      setWalletAddress(null);
      setUserBalance(null);
    }
  };

  React.useEffect(() => {
    fetchGasPrice();
  }, []);

  const fetchGasPrice = async () => {
    const res = await axios.get(
      `https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=${API_KEY}`
    );
    setGasPrice(res?.data?.result?.SafeGasPrice);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navOptions = [
    {
      name: "Overview",
      link: "/overview",
    },
    {
      name: "Send",
      link: "/send",
    },
    {
      name: "On ramp",
      link: "#",
    },
    {
      name: "Subscription",
      link: "#",
    },
    {
      name: "Social Recovery",
      link: "#",
    },
  ];

  const drawer = (
    <Box
      sx={{
        height: "100%",
        bgcolor: "#151515",
        color: "white",
        width: { md: "250px" },
      }}
    >
      <Box
        sx={{
          height: "6rem",
          display: "flex",
          alignItems: "center",
          mt: "1rem",
        }}
      >
        <Box sx={{ m: "0 12px" }}>
          <img
            src={logo}
            alt="WalletEx"
            style={{ height: "3.5rem", borderRadius: "10px" }}
          />
        </Box>
        <Box>
          {/* {!walletAddress ? (
            <Box sx={{ mb: "1rem" }}>
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "12px", md: "20px" },
                  letterSpacing: "2px",
                }}
              >
                WalletEx
              </Box>
              <Box
                sx={{
                  fontSize: { xs: "10px", md: "14px" },
                  letterSpacing: "1px",
                }}
              >
                Portfolio
              </Box>
            </Box>
          ) : (
            <Box>
              <Box sx={{ fontWeight: "bold", fontSize: "14px" }}>
                {walletAddress && truncateEthAddress(walletAddress)}
              </Box>
              <Box sx={{ fontSize: "20px", fontWeight: "bold" }}>
                {userBalance && `${parseFloat(userBalance).toFixed(3)} ETH`}
              </Box>
            </Box>
          )} */}
          <Box sx={{ mb: "1rem" }}>
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "12px", md: "20px" },
                letterSpacing: "2px",
              }}
            >
              WalletEx
            </Box>
            <Box
              sx={{
                fontSize: "17px",
                letterSpacing: "1px",
              }}
            >
              Portfolio
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Toolbar /> */}
      <Divider />
      <List>
        {navOptions.map((value, index) => (
          <Link to={value.link}>
            <ListItem
              key={value.name}
              disablePadding
              onClick={index > 1 ? notify : null}
              sx={{ width: drawerWidth }}
            >
              <ListItemButton
                href={value.link}
                sx={{
                  py: "7px",
                  my: "13px",
                  "&:hover": { backgroundColor: "rgb(100,100,100,0.2)" },
                  borderRight: `${
                    navUrl === value.name ? "5px solid #c000ff" : ""
                  }`,
                }}
                onClick={() => {
                  setNavUrl(value.name);
                }}
              >
                <Box sx={{ mr: "10px" }}>
                  {index === 0 && (
                    <RemoveRedEyeIcon
                      style={{
                        height: "1.4rem",
                        width: "1.4rem",
                        color: `${
                          navUrl === value.name ? "#c000ff" : "#909090"
                        }`,
                      }}
                    />
                  )}
                  {index === 1 && (
                    <Send
                      style={{
                        color: `${
                          navUrl === value.name ? "#c000ff" : "#909090"
                        }`,
                        height: "1.4rem",
                        width: "1.4rem",
                      }}
                    />
                  )}
                  {index === 2 && (
                    <RampLeft
                      style={{
                        color: `${
                          navUrl === value.name ? "#c000ff" : "#909090"
                        }`,
                        height: "1.4rem",
                        width: "1.4rem",
                      }}
                    />
                  )}
                  {index === 3 && (
                    <CardMembershipIcon
                      style={{
                        color: `${
                          navUrl === value.name ? "#c000ff" : "#909090"
                        }`,
                        height: "1.4rem",
                        width: "1.4rem",
                      }}
                    />
                  )}
                  {index === 4 && (
                    <InterestsIcon
                      style={{
                        color: `${
                          navUrl === value.name ? "#c000ff" : "#909090"
                        }`,
                        height: "1.4rem",
                        width: "1.4rem",
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    fontSize: "17px",
                    fontWeight: `${navUrl === value.name ? "800" : "600"}`,
                    color: `${navUrl === value.name ? "white" : "#909090"}`,
                  }}
                >
                  {value.name}
                </Box>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
    </Box>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", backgroundColor: "#090909" }}>
      <CssBaseline />
      <AppBar
        sx={{
          // width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { xs: "0", md: `${drawerWidth}px` },
          backgroundColor: { xs: "#191919", md: "#191919" },
          boxShadow: "0px 0px 20px 10px black",
        }}
      >
        <Toolbar
          sx={{
            bgcolor: "#090909",
            display: { xs: "none", md: "block", fontWeight: "bold" },
            height: "7rem",
          }}
        >
          <Typography
            noWrap
            component="div"
            sx={{
              p: "1rem 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              ml: { xs: "0", md: `${drawerWidth}px` },
              height: "100%",
            }}
          >
            <Box sx={{ fontWeight: "bold", fontSize: "32px", opacity: "0.7" }}>
              {navUrl}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ml: "10px",
                gap: 2,
                height: "100%",
              }}
            >
              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={information} alt="" />
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  fontSize: "17px",
                  gap: "2px",
                  fontWeight: "bold",
                }}
              >
                <Badge
                  badgeContent={`${gasPrice || "0"}`}
                  color="primary"
                  sx={{ fontWeight: "bold", fontSize: "17px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Icon height={400}>
                    <LocalGasStationIcon sx={{ opacity: "0.7" }} />
                  </Icon>
                </Badge>
                <span style={{ fontSize: "17px" }}>Gwei</span>
              </Box>

              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: "1rem",
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                }}
              >
                WalletEx
              </Box> */}
              <Button
                sx={{
                  borderRadius: "5rem",
                  bgcolor: "white",
                  color: "black",
                  fontSize: "17px",
                  fontWeight: "bold",
                  height: "3rem",
                  width: "12rem",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#A100ED",
                    color: "white",
                  },
                }}
                onClick={handleClick}
              >
                {loading ? (
                  <CircularProgress size={25} sx={{ color: "black" }} />
                ) : walletAddress ? (
                  `Remove Wallet`
                ) : (
                  "Connect Wallet"
                )}
              </Button>
            </Box>
          </Typography>
        </Toolbar>

        <Toolbar
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            p: "10px 1rem",
            display: { xs: "flex", md: "none" },
          }}
        >
          
              <IconButton onClick={handleDrawerToggle}>
                <img src={ham} alt="Menu" />
              </IconButton>
              {walletAddress ?
            <>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between'}}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  fontSize: "17px",
                  gap: "2px",
                  fontWeight: "bold",
                }}
              >
                <Badge
                  badgeContent={`${gasPrice || "0"}`}
                  color="primary"
                  sx={{ fontWeight: "bold", fontSize: "17px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Icon height={400}>
                    <LocalGasStationIcon sx={{ opacity: "0.7" }} />
                  </Icon>
                </Badge>
                <span style={{ fontSize: "15px" }}>Gwei</span>
              </Box>
                <IconButton onClick={handleClick}>
                  <PowerSettingsNewIcon sx={{color:"white"}} />
                </IconButton>
              </Box>
            </>
            :
            <>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', gap:1 }}>
              <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  fontSize: "17px",
                  gap: "2px",
                  fontWeight: "bold",
                }}
              >
                <Badge
                  badgeContent={`${gasPrice || "0"}`}
                  color="primary"
                  sx={{ fontWeight: "bold", fontSize: "17px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Icon height={400}>
                    <LocalGasStationIcon sx={{ opacity: "0.7" }} />
                  </Icon>
                </Badge>
                <span style={{ fontSize: "15px" }}>Gwei</span>
              </Box>
              </Box>
              <Box>
              <img src={logo} alt="WalletEx" style={{height: '100%', width: '2.5rem', marginTop: '15px'}}/>
              </Box>
                
            </Box>
            </>
          }
        </Toolbar>
      </AppBar>

      <Box sx={{ width: { md: drawerWidth } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "12rem",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              // boxSizing: "border-box",
              width: { xs: "30vw", md: drawerWidth },
              borderRight: "2px solid #151515",
              overflowX: "hidden",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          // p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
