import React from 'react'

const Divider = () => {
  return (
    <div style={{
        border: '1px solid #262626',
        width: '95%'
    }}/>
  )
}

export default Divider