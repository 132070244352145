import { Avatar, Box } from "@mui/material";
import React, { useContext, useState } from "react";
import Title from "./reusable-components/Title";
import navigation from "../assets/navig.svg";
import profile from "../assets/profile.svg";
import PurpleButton from "./reusable-components/PurpleButton";
import GrayInput from "./reusable-components/GrayInput";
import { WalletContext } from "./WalletContext";

const SendEthTransfer = () => {

  const [contractAddress, setContractAddress] = useState("");
  const [receiverAddress, setreceiverAddress] = useState("")
  const [tokenID, setTokenID] = useState(0)

  const {transferNFT}= useContext(WalletContext)

  const submitHandler = (e) => {
    e.preventDefault();
    
    transferNFT(contractAddress , receiverAddress , tokenID);

    setContractAddress("");
    setreceiverAddress("");
    setTokenID("")
  }

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/* <Avatar src={navigation} sx={{ m: "0 15px 0 0" }} />
        <Title>Ethereum</Title> */}
      </Box>
      <Box
        sx={{
          p: "0px 20px",
        }}
      >
      
            <GrayInput value={contractAddress} onChange={(e) => {setContractAddress(e.target.value)}} placeholder="0xef6f28bca8c5b53fe969afc4271b95c6ebb8ca43" label="Contract Address"/>
            <GrayInput value={receiverAddress} onChange={(e) => {setreceiverAddress(e.target.value)}} placeholder="0xef6f28bca8c5b53fe969afc4271b95c6ebb8ca43" label="Receiver's Address"/>
            <GrayInput value={tokenID} onChange={(e) => {setTokenID(e.target.value)}} placeholder="0" label="Token ID" />
            <Box sx={{mt: '1rem'}}>
            <PurpleButton onClick={submitHandler}>Send NFTs</PurpleButton>
        </Box>
      </Box>

      {/* <Box
        sx={{
          bgcolor: "rgb(250, 250, 250, 0.04)",
          m: "10px 0",
          p: "15px 20px",
          border: '2px solid red'
        }}
      >
        <Box sx={{fontSize: {xs: '14px', md: '20px'}}}>Asset</Box>
        <Box
          sx={{
            height: "8rem",
            display: "flex",
            alignItems: "center",
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Box sx={{ fontSize: {xs: '18px', md:"24px"}, fontWeight: '600' }}>No available positions on Ethereum</Box>
            <Box sx={{ fontSize: {xs: '16px', md:"20px"}, fontWeight: '600', color: '#8C8C8C' }}>Balance:0</Box>
          </Box>
          <Box>
            <Box sx={{fontWeight: 'bold', fontSize: '64px', color: '#8C8C8C'}}>0</Box>
          </Box>
        </Box>
      </Box> */}
      
    </Box>
  );
};

export default SendEthTransfer;
