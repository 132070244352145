import { Box, CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Subtitle from './reusable-components/Subtitle'
import Title from './reusable-components/Title'
import Description from './reusable-components/Description'
import pictureIcon from "../assets/pictureIcon.svg"
import axios from 'axios'
import { ALCHEMY_API_KEY } from '../utils/constants'
import { WalletContext } from './WalletContext'

const NFTs = () => {

  const [nfts , setNfts] = useState(null);
  const [isLoading , setIsLoading] = useState(false);

  const {walletAddress} = useContext(WalletContext);

  useEffect(() => {
    if(walletAddress){
      setIsLoading(true)
      fetchNFTData();
    }
    
  }, [walletAddress])


  const fetchNFTData = async () => {
  try{  
    const baseURL = `https://eth-mainnet.g.alchemy.com/nft/v3/${ALCHEMY_API_KEY}/getNFTsForOwner/`;
    // Replace with the wallet address you want to query for NFTs:
    const ownerAddr = walletAddress;
    const pageSize = 2;

    // Construct the axios request:
    var config = {
      method: 'get',
      url: `${baseURL}?owner=${ownerAddr}&pageSize=${pageSize}`
    };

    // Make the request and print the formatted response:
    axios(config)
      .then(response => setNfts(response?.data?.ownedNfts))
      .catch(error => console.log(error));
    
       setIsLoading(false);
      }catch(error){
        console.log(Error);
      }
      
  }


  return (

<>
 {  isLoading ? <CircularProgress /> :  <Box>
      {/* <Box sx={{ width: 'fit-content' }}>
        <Description>No value by floor price</Description>

        <Title>$0.00</Title>
      </Box> */}
      <Box sx={{
        bgcolor: '#1F1D1D',
        minHeight: { xs: '25rem', md: '38rem' },
        width: '100%',
        mt: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        borderRadius: "10px",
        p: '2rem'
      }}>
      {
        nfts?.map((nft) =>{ 
          return   <Box sx={{
            // width: { md: '70vw' },
            // height: { md: '23rem' },
            bgcolor: '#1D1D1D',
            border: '2px solid #423F3F',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: '1rem',
            my: '5px',
            mx: {sm: '10px'}
          }}>
            <Box>
              <img src={nft?.image?.thumbnailUrl} alt='Profile' style={{width: '60vw', maxWidth: '30rem'}} />
            </Box>

            <Box> 
              {nft?.name}
            </Box>
   
          </Box>
        })
      }
      </Box>
    </Box>
}
    </>
  )
}

export default NFTs
